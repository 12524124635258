import { makePath } from 'src/app/popsicle/router/paths';

/**
 * ANALYTICS NOTE:
 * The 2nd arg to makePath should be identical to iOS SCREEN
 * names.
 *
 * @see iOS's ScreenEvent.swift
 */
export const home = makePath('/', 'Home');
export const termsAndConditions = makePath('/terms', 'Legal');
export const privacy = makePath('/privacy', 'Capsule Pharmacy Privacy Policy');
export const faq = makePath('/faq', 'FAQ');
export const questions = makePath('/questions', 'Questions');
export const about = makePath('/about', 'About');
export const howItWorks = makePath('/how-it-works', 'How It Works');
export const locations = makePath('/locations', 'Locations');
export const doctors = makePath('/doctors', 'Doctors');
export const loggedOutShowYourDoctor = makePath('/show-your-doctor', 'Show Your Doctor');
export const hipaa = makePath('/hipaa', 'HIPAA Privacy');
export const getStarted = makePath('/get-started', 'Get Started');
export const getStartedNew = makePath('/get-started-new', 'Get Started New');
export const getStartedNewContact = makePath('/get-started-new/contact', 'Get Started New - Contact');
export const getStartedNewCaptcha = makePath('/get-started-new/captcha', 'Get Started New - Captcha');
export const getStartedNewTransferIntent = makePath(
  '/get-started-new/transfer-intent',
  'Get Started New - Transfer Intent'
);
export const getStartedNewWaitlist = makePath('/get-started-new/waitlist', 'Get Started New - Waitlist');
export const getStartedNewWaitlistConfirmation = makePath(
  '/get-started-new/waitlist-confirmation',
  'Get Started New - Waitlist Confirmation'
);
export const welcome = makePath('/welcome', 'Welcome');
export const promoLinkedSuccessfully = makePath('/rx/promo_linked', 'Promotion Linked Successfully');
export const employeeBenefitsPage = makePath('/rx/employee_benefits', 'Employee Benefits');

export const login = makePath('/auth/login', 'Auth - Login');
export const logout = makePath('/auth/logout', 'Auth - Logout');
export const mfaInterstitial = makePath('/auth/mfa/notice', 'Auth - MFA Interstitial');
export const mfaForm = makePath('/auth/mfa/form', 'Auth - MFA Form');
export const forgotPassword = makePath('/auth/forgot_password', 'Auth - Forgot Password');
export const forgotPasswordSuccess = makePath('/auth/forgot_password/success', 'Auth - Forgot Password - Success');
export const resetPassword = makePath('/auth/reset_password/:encodedUserId/:token', 'Auth - Reset Password');
export const resetPasswordWithApiKey = makePath(
  '/auth/reset_password/:encodedUserId/:token/:code',
  'Auth - Reset Password'
);
export const passwordResetRequired = makePath('/auth/password_reset_required', 'Auth - Password Reset Required');

// logged in
export const medications = makePath('/rx/home', 'Rx - Prescription');

/**
 * The `:stepName` path param is a value from the enum `EscriptOnboardingStepName`
 */
export const newOnboardingStep = makePath('/rx/onboarding/:stepName', 'Welcome to Capsule.');
export const medicationDetails = makePath('/rx/home/:prescriptionId/details', 'Rx - Medication Details');
export const medicationDetailsForTransfer = makePath(
  '/rx/home/:prescriptionId/transfer_details',
  'Rx - Medication Details for Transfer'
);
export const medicationGuides = makePath('/rx/prescriptions/:ndc/medication_guide', 'Rx - Show Medication Guide');
export const shop = makePath('/rx/shop', 'Rx - Shop');
export const shop2 = makePath('/rx/shop', 'Rx - Shop');
export const shop2Category = makePath('/rx/shop/c', 'Rx - Shop');
export const orders = makePath('/rx/orders', 'Rx - Order History');
export const contact = makePath('/rx/contact', 'Rx - Contact');
export const archivedPrescriptions = makePath('/rx/archived', 'Rx - Archived Prescriptions');
export const showYourDoctor = makePath('/rx/show-your-doctor', 'Rx - Show Your Doctor');
export const tellYourDoctor = makePath('/rx/tell-your-doctor', 'Rx - Tell Your Doctor');
export const partnerOnboardingFlow = makePath('/rx/onboarding', 'Rx - Claim Your Account');

export const checkout = makePath('/rx/checkout', 'Rx - Checkout - Detail');

/** aka: "upsell essentials" */
export const checkoutAddons = makePath('/rx/checkout-addons', 'Rx - Checkout Addons');

// Viewing cart items, during checkout
export const checkoutCartItems = makePath('/rx/checkout/:cartId/items', 'Rx - Checkout - Detail - Cart Items');
export const checkoutSelectAddress = makePath(
  '/rx/checkout/:cartId/delivery_address/list',
  'Rx - Checkout - Detail - Delivery Address - List'
);
export const checkoutAddAddress = makePath(
  '/rx/checkout/:cartId/delivery_address/create',
  'Rx - Checkout - Delivery Address - Create'
);

export const checkoutSelectPickupStation = makePath(
  '/rx/checkout/:cartId/pickup_station/list',
  'Rx - Checkout - Details - Pickup Station - List'
);

export const checkoutSelectCreditCard = makePath(
  '/rx/checkout/:cartId/credit_card/list',
  'Rx - Checkout - Detail - Credit Card - List'
);
export const checkoutAddCreditCard = makePath(
  '/rx/checkout/:cartId/credit_card/create',
  'Rx - Checkout - Detail - Credit Card - Create'
);
export const checkoutSelectWindow = makePath('/rx/checkout/:cartId/delivery_window', 'Rx - Checkout - Delivery Window');
export const checkoutSelectDeliveryMethod = makePath(
  '/rx/checkout/:cartId/delivery_method',
  'Rx - Checkout - Delivery Method'
);

export const checkoutContactInfo = makePath('/rx/checkout/:cartId/contact_info', 'Rx - Checkout - Contact Info');

export const checkoutAllergyProfile = makePath(
  '/rx/checkout/:cartId/allergy_profile',
  'Rx - Checkout - Allergy Profile'
);

export const checkoutMedicalHistory = makePath(
  '/rx/checkout/:cartId/medical_history',
  'Rx - Checkout - Medical History'
);

export const checkoutAddIdentification = makePath(
  '/rx/checkout/:cartId/identification/upload',
  'Rx - Checkout - Detail - Identification - Upload'
);
export const checkoutAccountSetup = makePath('/rx/checkout/:cartId/account_setup', 'Account Setup');

export const validateAddress = makePath('/rx/validateAddress', 'Validate address');
export const validateAddressV2 = makePath('/rx/validate_address', 'Validate address');

// Viewing order items, post checkout
export const checkoutOrderItems = makePath(
  '/rx/checkout/:orderId/status/items',
  'Rx - Checkout - Detail - Order Items'
);

export const orderTracking = makePath('/rx/orders/:orderId/tracking', 'Rx - Order Tracking');

export const rescheduleOrder = makePath(
  '/rx/orders/:orderId/reschedule/:stepName', // :stepName represented by enum "RescheduleOrderStepName"
  'Rx - Multiple Past Failures - Reschedule Order With Address Verification'
);

export const rescheduleDeliveryWindows = makePath(
  '/rx/orders/:orderId/reschedule/delivery_window',
  'Rx - Orders - Reschedule Delivery Window'
);

export const orderStatusDeepLink = makePath('/track/:shortCodeId', 'Order - Status');
export const orderItems = makePath(
  '/rx/orders/:orderId/status/order_items',
  'Rx - Order - Detail - Status - Order Items'
);

export const accountPage = makePath('/rx/account', 'Account');
export const AccountPageAddDependent = makePath('/rx/account/add/dependent', 'Account - add dependent');
export const AccountPageAddAddress = makePath('/rx/account/add/address', 'Account - add address');
export const AccountPageCreditCard = makePath('/rx/account/add/credit-card', 'Account - add credit card');
export const AccountPageInsurance = makePath('/rx/account/add/insurance', 'Account - add insurance');
export const AccountPagePhone = makePath('/rx/account/update-phone', 'Account - update phone');
export const AccountPagePhoneUpdateSuccess = makePath(
  '/rx/account/update-phone/success',
  'Account - update phone success'
);

export const loggedInTransferRxDeprecated = makePath(
  '/rx/transferpharmacy/:patientId',
  'Rx - Transfer Pharmacy - Detail'
);

// Logged In Transfer
export const loggedInTransferRx = makePath('/rx/transferpharmacy', 'Rx - Transfer');
export const loggedInTransferPrompt = makePath('/rx/transfer', 'Rx - Transfer - Prompt');
export const loggedInTransferRxSuccess = makePath('/rx/transfer/confirm', 'Rx - Transfer - Confirm');
export const loggedInTransferFAQ = makePath('/rx/transfer/faq', 'Rx - Transfer - FAQ');
export const loggedInFAQ = makePath('/rx/faq', 'Rx - FAQ');
export const loggedInQuestions = makePath('/rx/questions', 'Rx - Questions');
export const loggedInTransferPostOrderPrompt = makePath('/rx/transfer-prompt', 'Rx - Transfer');
export const loggedInTransferAppInterstitialPrompt = makePath('/rx/transfer-interstitial', 'Rx - Transfer');
export const loggedInTransferIntro = makePath('/rx/transfer-start', 'Rx - Transfer- Start');

// Logged In Health Profile Update
export const loggedInHealthProfile = makePath('/rx/health_profile/:stepName', 'Health Profile - Update');

// native
export const nativeFaq = makePath('/native/faq', 'Native - Faq');
export const nativeTerms = makePath('/native/terms', 'Native - Terms');
export const nativeHipaa = makePath('/native/hipaa', 'Native - Hipaa');
export const nativePrivacy = makePath('/native/privacy', 'Native - Privacy');
export const nativeShowYourDoctor = makePath('/native/show-your-doctor', 'Native - Rx - Show Your Doctor');
export const nativeMedicationGuides = makePath(
  '/native/prescriptions/:ndc/medication_guide',
  'Native - Show Medication Guide'
);

// landing pages
export const CCARedirectToPromoPage = makePath('/CCA', 'Redirect to Promotional Landing Page');
export const MorganStanleyRedirectToPromoPage = makePath('/morganstanley', 'Redirect to Promotional Landing Page');
export const ChicagoTransitRedirectToPromoPage = makePath('/chicagotransit', 'Redirect to Promotional Landing Page');
export const EHEHealthRedirectToPromoPage = makePath('/ehehealth', 'Redirect to Promotional Landing Page');
export const PromotionalLandingPage = makePath('/l/:promo_code', 'Promotional Landing Page');
export const loggedInPromoOnboardingPage = makePath('/rx/promo_onboarding', 'Rx - Promo Onboarding');
export const loggedOutPromoOnboardingWaitlist = makePath('/promo_onboarding_waitlist', 'Promo Onboarding Waitlist');
export const loggedOutPromoOnboardingWaitlistSuccess = makePath('/promo_waitlist_success', 'Promo Waitlist Success');
export const loggedInInputWorkEmailToVerifyPage = makePath('/rx/verify_email', 'Rx - Verify Email');
export const maintenance = makePath('/maintenance', 'Something Went Wrong');
export const CignaRedirectToPromoPage = makePath('/cigna', 'Redirect to Promotional Landing Page');
export const checkZipCode = makePath('/check-zip-code', 'Check Zip Code Deliverable');
export const checkProviderZipCode = makePath('/partner-check-zip-code', 'Check Zip Code Deliverable for Providers');
export const devotedRedirectToPromoPage = makePath('/devoted', 'Redirect to Promotional Landing Page');
export const openAppLanding = makePath('/open-app', 'Open Capsule App Page');

// ClearRx Demo Pharmacy Hub Pages
export const pharmacyHubTriggerDemo = makePath('/pharmacy-hub-demo', 'Pharmacy Hub Demo');
export const spanishPharmacyHubTriggerDemo = makePath('/es/pharmacy-hub-demo', 'Pharmacy Hub Demo (Spanish)');
export const pharmacyHubJourney = makePath('/pharmacy-hub-journey', 'Pharmacy Hub Demo');
export const spanishPharmacyHubJourney = makePath('/es/pharmacy-hub-journey', 'Pharmacy Hub Demo (Spanish)');
export const clearRxNotFound = makePath('/hub/not-found', 'ClearRx | Not Found');
export const spanishClearRxNotFound = makePath('/es/hub/not-found', 'ClearRx | Not Found (Spanish)');
export const clearRxInvalidCode = makePath('/hub/invalid', 'ClearRx');
export const spanishClearRxInvalidCode = makePath('/es/hub/invalid', 'ClearRx (Spanish)');
export const clearRxShortCodeProxy = makePath('/c/:hash', 'ClearRx');
export const clearRxSelect = makePath('/hub/rx', 'ClearRx');
export const spanishClearRxSelect = makePath('/es/hub/rx', 'ClearRx (Spanish)');
export const clearRxTerms = makePath('/hub/terms', 'ClearRx');
export const clearRxPrivacy = makePath('/hub/privacy', 'ClearRx');
export const clearRxHIPAA = makePath('/hub/hipaa', 'ClearRx');

// Flex delivery landing pages
export const flexdeliveryRedirectToPromoPage = makePath('/flexdelivery', 'Redirect to Promotional Landing Page');

export const notFound = makePath('/not-found', 'Not Found');
export const urlShortener = makePath('/cc/:hash', 'Proxy for url shortening service');
export const redirectToInsurance = makePath('/upload-insurance/:hash', 'Redirect for insurance upload');

// Redirects
// These paths can only be used on <a> tags so that they request the relevant the next.config redirect
export const careers = makePath('/careers', 'Redirecting to Job Board');
export const blog = makePath('/blog', 'Redirecting to Blog');
export const press = makePath('/press', 'Redirecting to Press');

// EXTERNAL
export const external = {
  facebook: 'https://www.facebook.com/capsulecares',
  twitter: 'https://www.twitter.com/capsulecares',
  instagram: 'https://www.instagram.com/capsulecares',
  appStore: 'https://itunes.apple.com/app/apple-store/id1092333652',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.capsule.capsule',
  openIOSApp: 'https://capsule.app.link/open-ios-app',
  downloadApp: 'https://capsule.app.link/download-app',
};

// Hard redirects to micro apps
export const redirectHome = () => {
  if (window) {
    window.location.href = `/${window.location.search}`;
  }
};

export const redirectQuestions = () => {
  if (window) {
    window.location.href = `/questions${window.location.search}`;
  }
};
